/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import textBlockStyle from '@stories/atoms/TextBlock';

const globalCss = (theme) => css`
  ${theme.font.face.headline}
  ${theme.font.face.copy}
  ${theme.font.face.accent}

  :root {
    --color-border-grey: ${theme.color.grey.g92};
    --container-width: 1200px;
    --gutter: 16px;
    --offset: 16px;

    --theme-font-copy: ${theme.font.copy.font};

    box-sizing: border-box;
    color: ${theme.color.copy};
    @media (min-width: 1200px) {
      --gutter: 20px;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font: ${theme.font.copy.font};
    margin: 0 !important;
    padding: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  // RESET
  input {
    font-family: inherit;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }

  p {
    margin: 0;
  }

  dialog {
    border: 0;
  }

  /* clears the 'X' from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: initial;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50%
      50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  input[type='search']:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
  }

  input[type='search'].dark::-webkit-search-cancel-button {
    filter: invert(1);
  }

  svg {
    width: 24px;
    height: 24px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  // webkit fix
  dialog:not([open]) {
    display: none;
  }

  select {
    font-family: inherit;
    font-size: inherit;
    :focus {
      outline-color: ${theme.color.outline};
    }
  }

  button {
    background: none;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
  }

  mark {
    background-color: transparent;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  ${textBlockStyle(theme)}

  // om- campaign can't override interactive elements
  [id^=om-] {
    z-index: 10 !important;
  }

  .lg-img-wrap img {
    background-color: hsl(0 0% 96%);
  }
`;

export default globalCss;
