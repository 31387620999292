import Color from 'color';
import merge from 'lodash/merge';

import { media } from '@stories/theming/settings';
import { ASSET_URL } from '@utils/constants';

import defaultTheme, { color as defaultColor } from './default';

// https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap
const fontFaceCopy = `@font-face {
  font-family: 'Rogan';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${ASSET_URL}/asset/fonts/rogan/rogan.woff2) format('woff2');
};

@font-face {
  font-family: 'Rogan';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${ASSET_URL}/asset/fonts/rogan/rogan_bold.woff2) format('woff2');
};
`;

const bodylabYellow = 'hsl(44, 100%, 60%)';
const blackOnYellow = 'hsl(0 0% 10%)';
const color = merge({}, defaultColor, {
  primary: 'hsl(209, 78%, 25%)',
  accent: bodylabYellow,
  highlight: bodylabYellow,
  primaryBackground: 'hsl(193 79% 51%)',
  secondary: 'hsl(0, 0%, 20%)',
  copy: 'hsl(0, 0%, 30%)',
  promotion: 'hsl(358, 65%, 58%)',
  hover: {
    primary: 'hsl(77, 55%, 34%)',
    copy: Color('hsl(193, 78%, 51%)').darken(0.15).toString()
  }
});

export const font = {
  family: {
    headline: "'Rogan', sans-serif",
    copy: "'Rogan', sans-serif",
    accent: "'Rogan', sans-serif"
  },
  size: {
    large: '22px',
    copy: '17px',
    small: '15px',
    footnote: '14px'
  },
  face: {
    headline: undefined,
    copy: fontFaceCopy,
    accent: undefined
  },
  copy: {
    font: 'normal 17px/140% "Rogan", sans-serif'
  },
  small: {
    font: 'normal 15px/140% "Rogan", sans-serif'
  },
  footnote: {
    font: 'normal 14px/140% "Rogan", sans-serif'
  },
  tiny: {
    font: 'normal 12px/140% "Rogan", sans-serif'
  },
  link: {
    textDecoration: 'underline',
    textDecorationColor: 'hsl(0, 0%, 50%)',
    textUnderlineOffset: '3px',
    ':hover': {
      color: color.primary,
      textDecorationColor: 'currentColor'
    }
  }
};

export const headline = {
  h1: {
    font: 'bold 40px/110% "Rogan", sans-serif',
    letterSpacing: '-0.01em',
    [media('desktop')]: {
      fontSize: '55px'
    }
  },
  h2: {
    font: 'bold 26px/110% "Rogan", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '33px'
    }
  },
  h3: {
    font: 'bold 22px/110% "Rogan", sans-serif',
    letterSpacing: '0.02em'
  },
  h4: {
    font: 'bold 20px/110% "Rogan", sans-serif'
  },
  h5: {
    font: 'bold 18px/120% "Rogan", sans-serif',
    letterSpacing: '0.02em'
  }
};

export const badge = {
  verticalAlign: 0,
  core: {
    alignItems: 'center',
    border: 'thin solid transparent',
    borderRadius: '4px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: '0.01em',
    lineHeight: '100%',
    padding: '3px 8px 2px',
    textTransform: 'uppercase'
  },
  new: {
    backgroundColor: bodylabYellow,
    borderColor: bodylabYellow,
    color: blackOnYellow
  },
  bestseller: {
    backgroundColor: color.grey.g92,
    borderColor: color.grey.g92,
    color: color.copy
  },
  discount: {
    backgroundColor: color.promotion,
    borderColor: color.promotion,
    color: 'white',
    padding: '1.5px 4px 1px'
  },
  freeShipping: {
    backgroundColor: color.grey.g85,
    borderColor: color.grey.g85,
    color: color.copy
  },
  freeText: {
    backgroundColor: color.grey.g97,
    borderColor: color.grey.g85,
    color: color.copy
  }
};

const buttonCommon = {
  borderRadius: '3px',
  letterSpacing: 0,
  textTransform: 'none'
};

const button = {
  primary: {
    solid: {
      ...buttonCommon,
      backgroundColor: bodylabYellow,
      border: `1px solid hsl(44, 100%, 60%)`,
      color: blackOnYellow,
      ':hover': {
        backgroundColor: Color(bodylabYellow).darken(0.2).hsl().string(),
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: `1px solid ${color.primary}`,
      color: color.primary,
      textTransform: 'none'
    }
  },
  secondary: {
    solid: {
      ...buttonCommon,
      backgroundColor: color.grey.g20,
      border: `1px solid ${color.grey.g20}`,
      color: 'white',
      textTransform: 'none',
      ':hover': {
        backgroundColor: Color(color.grey.g20).lighten(0.25).hsl().string(),
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      borderColor: color.secondary,
      color: color.secondary,
      textTransform: 'none'
    }
  },
  tertiary: {
    solid: {
      backgroundColor: color.grey.g92,
      border: `1px solid ${color.grey.g92}`,
      color: color.grey.g20,
      textTransform: 'none',
      ':hover': {
        backgroundColor: Color(color.grey.g92).darken(0.05).hsl().string(),
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: 'white',
      textTransform: 'none'
    }
  },
  small: {
    compensateY: '1px',
    font: 'normal 17px/17px "Rogan", sans-serif',
    padding: '9px 1.5rem'
  },
  regular: {
    compensateY: '1px',
    font: 'normal 17px/17px "Rogan", sans-serif',
    padding: '.7rem 1.5rem'
  },
  big: {
    compensateY: '1px',
    font: 'normal 18px/24px "Rogan", sans-serif',
    padding: '.75rem 1.5rem'
  }
};

export const header = {
  backgroundColor: color.primary,
  burgerColor: 'white',
  color: 'white',
  logo: {
    height: '29px'
  },
  container: { backgroundColor: color.primary },
  navigation: {
    container: {
      ...font.small,
      color: color.grey.g40,
      lineHeight: '145%'
    },
    firstLevelItems: {
      ':hover': {
        color: color.hover.copy
      },
      ...font.copy,
      fontWeight: 'regular',
      lineHeight: '2.5rem',
      textTransform: 'none'
    },
    secondLevelItems: {
      ':hover': {
        color: color.hover.copy
      },
      ...headline.h4,
      display: 'block',
      marginBottom: '.5rem'
    },
    thirdLevelItems: {
      ':hover': {
        color: color.hover.copy
      }
    }
  },
  searchInput: {
    borderRadius: '3px',
    '& input': {
      border: 'none',
      boxShadow: '0 0 16px -3px hsl(0 0% 0% / 20%)'
    },
    '& input:focus-visible': {
      outline: 'none'
    }
  },
  topBar: {
    backgroundColor: 'hsl(0 0% 100% / 80%)',
    color: color.grey.g40,
    backdropFilter: 'blur(10px)',
    font: font.footnote.font
  }
};

export const newsletter = {
  backgroundColor: color.secondary,
  color: 'white'
};

export const footer = {
  backgroundColor: color.primary,
  color: 'white',
  section: {
    headline: {
      color: 'white'
    }
  }
};

const theme: typeof defaultTheme = merge({}, defaultTheme, {
  storeId: 'bodylab',
  color,
  button,
  border: {
    color: 'hsl(0, 0%, 60%)',
    radius: '3px'
  },
  badge,
  header,
  headline,
  font,
  footer,
  newsletter
});

export default theme;

// hitPlaceholder: '/skin/frontend/vitafy/vomachterhof/images/svg/logo.svg' !default;

//   colorCatcher: #387938;
// $font-color: $color-secondary;
//
// $color-background-primary: lighten($color-primary, 25);
// $color-background-dark: $color-secondary;
//
// $font-size-copy: 20px;
// $font-size-small: 18px;
//
// $default-border-radius: 3px;
//
// $color-grey-90: hsl(0, 0%, 90%);
